<div class="pt-4 pl-4 pr-4" *transloco="let t; read: 'registerAndTest'">
  <div class="card register-test shadow border-0 d-lg-block">

    <div class="card-header px-0 py-0">
      <span class="title">
        {{ t('registerHeader') }}
      </span>
    </div>

    <div class="card-body px-5 py-4 mt-3">
      <div class="container-fluid">

        <form [formGroup]="registerAndTestForm" (submit)="sendTest($event)">
          <div class="form-group align-items-center flex-group row">
            <label class="control-label col-sm-3 mb-0" for="teacherName">
              <span>{{ t('teacherNameLabel') }}:</span>
            </label>
            <div class="col-sm-9">

              <!-- show info -->
              <ng-container *ngIf="sendTestSucceeded$|async; else editname">
                <span>{{ registerAndTestForm.get('teacherName').value }}</span>
              </ng-container>

              <!-- allow edit -->
              <ng-template #editname>
                <input formControlName="teacherName"
                  class="form-control input-box" type="text" />
              </ng-template>
            </div>
          </div>

          <div class="form-group align-items-center flex-group row">
            <label class="control-label col-sm-3 mb-0" for="selectTestPool">
              <span>{{ t('selectTestPool') }}:</span>
            </label>
            <div class="col-sm-9">

              <!-- show info -->
              <ng-container *ngIf="sendTestSucceeded$|async; else editPool">
                <span>
                  {{ selectedPoolName$|async }}
                </span>
              </ng-container>

              <!-- allow edit -->
              <ng-template #editPool>
                <select class="custom-select" formControlName="testPool"
                  [ngClass]="{'touched': this.registerAndTestForm.pristine}"
                  (change)="poolNameOnChanges($event.target)">
                  <option [value]="null" selected hidden disabled>
                    {{t('selectOnePlaceHolder')}}</option>
                  <option [value]="pool.synlabId"
                    *ngFor="let pool of pools$|async">
                    {{ pool.name }}
                  </option>
                </select>
              </ng-template>

            </div>
          </div>

          <div class="form-group align-items-center flex-group row">
            <label class="control-label col-sm-3 mb-0" for="testNumber">
              <span>{{ t('testNumber') }}:</span>
            </label>
            <div class="col-sm-9">

              <ng-container>
                <!-- show info -->
                <ng-container
                  *ngIf="sendTestSucceeded$|async; else edittestnumber">
                  <span>{{ registerAndTestForm.get('testNumber').value }}</span>
                </ng-container>

                <!-- allow edit -->
                <ng-template #edittestnumber>
                  <input formControlName="testNumber"
                    class="form-control input-box" type="text" maxlength="12"
                    placeholder="{{t('examplePlaceHolder')}}"
                    [ngClass]="{'touched': this.registerAndTestForm.pristine}" />
                </ng-template>
              </ng-container>

              <ng-container *ngIf="!(sendTestSucceeded$|async)">
                <!-- ui validation error -->
                <small class="text-danger ui"
                  *ngIf="!registerAndTestForm.get('testNumber').pristine && registerAndTestForm.get('testNumber').errors?.pattern">
                  {{ t('testNumberValidationLabel') }}
                </small>

                <!-- lookup validation error -->
                <small class="text-danger lookup"
                  *ngIf="(getLookupValue$|async) == true && !(getLookupInProgress$|async) && !registerAndTestForm.get('testNumber').errors?.pattern">
                  {{ t('testNumberLookupValidationLabel') }}
                </small>

                <!-- lookup is loading -->
                <small *ngIf="(getLookupInProgress$|async)">
                  {{ t('loadingLabel') }}
                </small>
              </ng-container>

            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">

              <!-- resend or go to list of result -->
              <ng-container *ngIf="sendTestSucceeded$|async; else showSubmit">
                <span class="float-right">
                  <button type="button" [routerLink]="['/results', 'list']"
                    routerLinkActive="router-link-active"
                    class="btn btn-outline-primary mr-2">{{t('backToMyResultButton')}}</button>
                  <button type="button" (click)="reset()"
                    class="btn btn-primary">{{t('testAnotherPoolButton')}}</button>
                </span>
              </ng-container>

              <!-- submit button -->
              <ng-template #showSubmit>
                <ng-container *ngIf="sendTestInProgress$|async; else enableBtn">
                  <button type="button" disabled
                    class="btn btn-primary float-right">
                    {{ t('loadingLabel') }}
                  </button>
                </ng-container>
                <ng-template #enableBtn>

                  <button type="submit"
                    [disabled]="isSubmitDisabled || !registerAndTestForm.valid || (getLookupValue$|async) == true || (getLookupInProgress$|async)"
                    class="btn btn-primary float-right">{{ t('sendTestButton')
                    }}</button>
                </ng-template>
              </ng-template>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>
