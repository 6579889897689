<!-- Button trigger modal -->
<button
  type="button"
  class="btn btn-primary"
  data-toggle="modal"
  data-target="#notAuthModal"
  [hidden]="true"
  #clickBtn
></button>

<!-- Modal -->
<div
  class="modal fade"
  id="notAuthModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="notAuthModalLabel"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="notAuthModalLabel">Unauthorized action</h5>
      </div>
      <div class="modal-body">
        <div class="d-flex">
          <span class="icon"><em class="fas fa-info-circle"></em></span>
          <span>
            <p class="mb-0">You have been logged out from SYNLAB Access.</p>
            <p class="mb-0">Click <strong>continue</strong> to log in again.</p>
          </span>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          (click)="logout()"
          data-dismiss="modal"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</div>
