<ul class="pagination">

        <!-- first button -->
        <li class="page-item disabled" *ngIf="page === 1" title="First">
          <span class="page-link">
            <img src="assets/images/pagination-icons/first.png" alt="first" class="img-fluid" />
          </span>
        </li>
        <li title="First" *ngIf="page > 1" class="page-item" (click)="changePage('first')">
          <span class="page-link">
            <img src="assets/images/pagination-icons/first.png" alt="first" class="img-fluid" />
          </span>
        </li>
      
        <!-- previous button -->
        <li class="page-item disabled" *ngIf="page <= 1" title="Previous">
          <span class="page-link">
            <img src="assets/images/pagination-icons/prev.png" alt="Previous" class="img-fluid" />
          </span>
        </li>
        <li class="page-item" *ngIf="page > 1" (click)="changePage('prev')" title="Previous">
          <span class="page-link">
            <img src="assets/images/pagination-icons/prev.png" alt="Previous" class="img-fluid" />
          </span>
        </li>
      
        <!-- result range -->
        <li class="page-item">
          <span class="range-detail f-height text-muted" *ngIf="total != 0">
            <span>
              <small>{{ resultRange }}</small>
            </span>
          </span>
        </li>
      
        <!-- next button -->
        <li class="page-item disabled" *ngIf="page >= maxPage" title="Next">
          <span class="page-link">
            <img src="assets/images/pagination-icons/next.png" alt="Next" class="img-fluid" />
          </span>
        </li>
        <li class="page-item" *ngIf="page < maxPage" (click)="changePage('next')" title="Next">
          <span class="page-link">
            <img src="assets/images/pagination-icons/next.png" alt="Next" class="img-fluid" />
          </span>
        </li>
      
        <!-- last button -->
        <li class="page-item" *ngIf="page < maxPage" (click)="changePage('last')" title="Last">
          <span class="page-link">
            <img src="assets/images/pagination-icons/last.png" alt="Last" class="img-fluid" />
          </span>
        </li>
        <li class="page-item disabled" *ngIf="page >= maxPage" title="Last">
          <span class="page-link">
            <img src="assets/images/pagination-icons/last.png" alt="Last" class="img-fluid" />
          </span>
        </li>
      </ul>