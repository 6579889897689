<div class="container py-4 user-container mb-150">
  <div class="row">
    <div class="col-12">
      <div class="d-flex mb-2">
        <div class="flx-1">
          <div id="fullBloodCount" class="page-title p-0 m-0"><em class="fas fa-tint"></em> Full Blood Count</div>
        </div>
        <div class="flx-1 mrg-auto">
          <h6 class="text-right mr-4">Date Performed: {{testDate}}</h6>
        </div>
      </div>
      <form>
        <table class="table" aria-describedby="fullBloodCount">
          <thead>
            <tr>
              <th scope="col" sortable="clientName">
                Parameter
              </th>
              <th scope="col" sortable="name">
                Actual Reading
              </th>
              <th scope="col" sortable="clientName">
                Unit of Measure
              </th>
              <th scope="col" sortable="clientName">
                Reference Range
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let t of testDetail">
              <td>
                <ngb-highlight [result]="t.parameter" [term]="state.searchTerm"></ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="t.actualReading" [term]="state.searchTerm"></ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="t.unitOfMeasure" [term]="state.searchTerm"></ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="t.referenceRange" [term]="state.searchTerm"></ngb-highlight>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <div class="text-right">
          <button class="btn btn-outline-primary mr-3" [routerLink]="['/home']">
            Back to My Test Results
          </button>
      </div>
    </div>
  </div>
</div>