export const localStorageKeys = {
  redirect: "App[URLRedirect]",
  langCode: "langCode",
  needReloadForCookiebot: "App[needReloadForCookiebot]"
};


export enum EStorageKeys {
  REDIRECT_URL = "APP_REDIRECT_URL"
}
