<div (clickOutside)="showOptions = false">
  <button type="button" class="btn btn-link decoration-none"
    (click)="showOptions = !showOptions"
    [title]="activeLang$ | async">
    <div class="d-flex align-items-center">
      <mat-icon>language</mat-icon>
    </div>
  </button>
  <ng-container *ngIf="showOptions">
    <div class="d-flex flex-column align-items-center position-absolute language-options">
      <button type="button" (click)="changeLanguage(code)"
        *ngFor="let code of langCodes"
        class="btn btn-link btn-language" [title]="code.languageValue"
        [ngClass]="{'selected': (activeLang$ | async) === code.languageValue}">
          <ng-container *ngTemplateOutlet="flagOrCodeTemplate; context: {code: code}"></ng-container>
      </button>
    </div>
  </ng-container>
</div>
<ng-template #flagOrCodeTemplate let-code="code">
  <div class="d-flex flex-column align-items-center">
    <span [class]="'font-size-3 fp ' + code?.flag"></span>
    <span class="language-value">{{ code?.languageValue }}</span>
  </div>
</ng-template>
