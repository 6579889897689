<ng-container *transloco="let t; read: 'resultsV2'">

  <mat-dialog-content>
    <span class="circle-bg">
      <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.2552 0C7.53347 0 6.00492 1.10172 5.46046 2.73509L5.03883 4H1.99984C1.26346 4 0.666504 4.59695 0.666504 5.33333C0.666504 6.06971 1.26346 6.66667 1.99984 6.66667V22.6667C1.99984 23.403 2.59679 24 3.33317 24H16.6665C17.4029 24 17.9998 23.403 17.9998 22.6667V6.66667C18.7362 6.66667 19.3332 6.06971 19.3332 5.33333C19.3332 4.59695 18.7362 4 17.9998 4H14.9608L14.5392 2.73509C13.9948 1.10172 12.4662 0 10.7445 0H9.2552ZM15.3332 6.66667H4.6665V21.3333H15.3332V6.66667ZM12.1499 4L12.0094 3.57836C11.8279 3.03391 11.3184 2.66667 10.7445 2.66667H9.2552C8.68129 2.66667 8.17177 3.03391 7.99028 3.57836L7.84974 4H12.1499Z" fill="#F9B232"/>
      </svg>
    </span>
    <h1>{{ t('transfer.dialog.confirmHeading', { destination: data }) }}</h1>
    <p>{{ t('transfer.dialog.confirmInfo') }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <a role="button" (click)="confirm(false)">Cancel</a>
    <button (click)="confirm(true)">Yes</button>
  </mat-dialog-actions>

</ng-container>
