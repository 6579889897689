<ng-container *ngIf="mode=='row'; else dropdownView">
  <div class="lib-locale">
    <div class="simple-row">
      <a role="button" *ngFor="let locale of availableLocale" (click)="changeLang(locale)" [class.active]="(activeLocale$ | async) == locale">
        {{ getDisplayLocale(locale) }}
      </a>
    </div>
  </div>
 </ng-container>


<ng-template #dropdownView>
  <div class="lib-locale">
    <button class="ee-text-button" [matMenuTriggerFor]="localeMenu" #open="matMenuTrigger">
      <img src="/assets/synlab-header/globe-icon.png" alt="globe icon">
      {{ activeLocale$ | async  }}
      <mat-icon color="primary" *ngIf="open.menuOpen==false">keyboard_arrow_down</mat-icon>
      <mat-icon color="primary" *ngIf="open.menuOpen==true">keyboard_arrow_up</mat-icon>
    </button>
    <mat-menu #localeMenu="matMenu">
      <ng-container *ngFor="let locale of availableLocale">
        <button mat-menu-item class="lib-locale-locale" [class.use-flags]="useFlags" (click)="changeLang(locale)" [class.active]="(activeLocale$ | async) == locale">
          <mat-icon  color="primary">check</mat-icon>
          {{ locale | slice: 0: 2 }}
          <span *ngIf="useFlags" [ngClass]="['fp', lookupCountry(locale)]"></span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</ng-template>
