<div class="upload-result-container-outer lab-order"
  *transloco="let t; read: 'labOrder'">

  <form autocomplete="off">
    <div class="content-wrapper selected-panel-container-outer mt-0">
      <ng-container *ngIf="orderInformation.get('synlabId') as sn">
        <ng-container *ngIf="orderInformation.get('testTubeNumber') as tn">

          <!-- synlab id -->
          <div class="scan-container" [formGroup]="orderInformation">
            <label class="label">{{ t('synlabId') }}</label>
            <div class="d-flex container-row">
              <div class="flex-1 synlab-input-field">
                <div class="input-group"
                  [ngClass]="{'border-danger': sn.dirty && !sn.valid && !(barcodeLoading$|async) && !(orderLoading$|async) && !sn.disabled }">
                  <input type="text" class="form-control" id="synlabId"
                    (input)="trimInput($event, 'synlabId')"
                    (keydown.enter)="onKeyEnter($event.target.value)"
                    formControlName="synlabId" tabindex="0" #synlabId
                    autocomplete="off">
                  <button class="btn btn-primary" type="button"
                    [disabled]="(barcodeLoading$ | async) ||  (orderLoading$ | async)|| !orderInformation.get('synlabId').valid || (isSchoolLoading$|async)"
                    (click)="testTubeFocus()">

                    <mat-spinner *ngIf="isSchoolLoading$|async; else showIcon"
                      diameter="20" aria-label="loading">
                    </mat-spinner>
                    <ng-template #showIcon>
                      <span class="material-icons">search</span>
                    </ng-template>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- test tube number -->
          <div class="scan-container mt-4" [formGroup]="orderInformation">
            <label class="label">{{ t('barcode') }}</label>
            <div class="d-flex container-row">
              <div class="flex-1 synlab-input-field">
                <div class="input-group"
                  [ngClass]="{'border-danger': tn.dirty && !tn.valid && !(barcodeLoading$ | async) && !tn.disabled }">
                  <input type="text" class="form-control" id="testTubeNumber"
                    formControlName="testTubeNumber"
                    (input)="trimInput($event, 'testTubeNumber')"
                    (keydown.enter)="onKeyEnter($event.target.value, 1)"
                    #testTubeNumber autocomplete="off">
                  <button class="btn btn-primary" type="button"
                    [disabled]="(barcodeLoading$|async) || (orderLoading$|async) || (!tn.valid || !sn.valid)"
                    (click)="checkExistingOrder('click')">
                    <mat-spinner *ngIf="barcodeLoading$|async; else showIcon2"
                      diameter="20" aria-label="loading">
                    </mat-spinner>
                    <ng-template #showIcon2>
                      <span class="material-icons">search</span>
                    </ng-template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!--schoolname-->
      <div class="mt-3"
        *ngIf="((isSchoolLoading$|async) || (schoolName$|async)); else noSchoolName">
        <ng-container *ngIf="(isSchoolLoading$|async); else schoolName">
          <span class="school-name-label">{{ t('schoolName') }}: Please
            wait...</span>
        </ng-container>
        <ng-template #schoolName>
          <span class="school-name-label">{{ t('schoolName') }}: {{schoolName$ |
            async}}</span>
        </ng-template>
      </div>
      <ng-template #noSchoolName>
        <div class="mt-3">
          <span class="school-name-label">{{ t('schoolName') }}: --- </span>
        </div>
      </ng-template>


      <!--poolname-->
      <div class="scan-container mt-4"
        *ngIf="!(orderExist$ | async) && orderInformation.get('testTubeNumber').value"
        [ngClass]="{'no-result': true}" [formGroup]="orderInformation">
        <label class="label">{{ t('poolName') }}</label>
        <div class="d-flex container-row">
          <div class="flex-1 poolname-input-field">
            <div class="input-group">
              <input type="text" class="form-control" id="poolName"
                formControlName="poolName" #poolName
                (keydown.enter)="sendOrder('enter')"
                (input)="trimInput($event, 'poolName')" autocomplete="off">
            </div>
          </div>
        </div>

        <div class="time-picker pt-3">
          <label class="dandtlabel">{{ t('samplingDateAndTime') }}</label>
          <div class="d-flex">
            <div class="hyRLSow3 flex-1 disabled2"
              [ngClass]="{'disabled': orderLoading$|async}">
              <mat-form-field>
                <input matInput [matDatepicker]="picker" [value]="defaultDate"
                  [disabled]="orderLoading$|async" [max]="today" #dateInput
                  (input)="manualDateInput(dateInput.value, 'manual')"
                  (dateChange)="manualDateInput(dateInput.value, 'picker')">
                <mat-datepicker-toggle [disabled]="orderLoading$|async"
                  matSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="time flex-1">
              <ngx-mat-timepicker-field color="primary"
                [disabled]="orderLoading$|async" [defaultTime]="defaultTime"
                (timeChanged)="timeChangedEvt($event)">
              </ngx-mat-timepicker-field>
            </div>
          </div>
          <small *ngIf="isInvalidDate$|async"
            class="text-danger date-error">Please enter a valid
            date</small>
        </div>
      </div>

      <div class="actions-container" [formGroup]="orderInformation">
        <div class="line"></div>
        <div class="buttons d-flex flex-row-reverse align-items-center">
          <button type="button" class="btn btn-primary"
            [disabled]="(orderExist$ | async) && ((orderInformation.get('automatic').value) || (orderInformation.get('automatic').enabled)) || !orderInformation.valid"
            (click)="sendOrder('submit')">
            <span *ngIf="!(orderLoading$ | async)">{{ t('nextOrder') }}</span>
            <mat-spinner *ngIf="orderLoading$ | async" diameter="20"
              aria-label="loading">
            </mat-spinner>
          </button>
          <span class="pr-3">
            <mat-checkbox formControlName="automatic">
              {{ t('automatic') }}
            </mat-checkbox>
          </span>
        </div>
      </div>
    </div>
  </form>


</div>
