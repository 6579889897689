<div *transloco="let t; read: 'header'"
  class="plasma-header web-header border-bottom box-shadow navbar navbar-expand-xl sticky-top pl-bg-2 pl-box-shadow-2"
  hidden>
  <div class="container-fluid px-0">
    <div class="d-flex flex-column flex-md-row nav-container">
      <div class="navbar-header"
        [ngClass]="{'white-labeled-icon-dk': isDKTenant$|async}">
        <a [routerLink]="redirectRoute$ | async" class="navbar-brand">
          <img [src]="logo$|async" alt="SYNLAB's logo"
            class="img-fluid pl-logo-1" />
        </a>
      </div>
      <div class="navbar-nav ml-2">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" *ngFor="let nav of nav; let first = first;">
            <a class="nav-link" [routerLink]="nav.route"
              *ngIf="auth.checkUserAccess(nav.access)|async">
              <span
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{nav.name}}">{{nav.name}}</span>
            </a>
          </li>
          <li class="nav-item">

            <a class="nav-link my-results"
              *ngIf="!(isDFPAdmin$ | async) && !(isLabUser$ | async) && !((canAccessCheckResult$ | async) && (!(isCustomerCare$ | async) || ((isCustomerCare$ | async) && !(isDKTenant$ | async)))) || (isMassTester$ | async) || (isEETenant$|async)"
              [routerLink]="fullCatalogueViewEnabled ? '/results/category' : '/results/list'">
              <span
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('myResultTabLabel')}}">{{
                t('myResultTabLabel') }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" *ngIf="isDKTenant$ | async"
              [href]="cnfg._envConfig.baseProfileUrl">
              <span
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('foreignIdLabel')}}">{{ t('foreignIdLabel')
                }}</span>
            </a>
          </li>
          <li class="nav-item"
            *ngIf="!(isLolliLabAdmin$ | async) && (bookingLinkEnabled$ | async) && !(canAccessCheckResult$ | async) && !(isSchool$ |async)">
            <a class="nav-link" [href]="bookingUrl$|async">
              <span
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('bookingLinkLabel')}}">{{
                t('bookingLinkLabel') }}</span>
            </a>
          </li>
          <li class="nav-item"
            *ngIf="(isAdmin$|async) || (canAccessCheckResult$ | async) && cnfg._envConfig.FullCatalogueViewEnabled?.toLowerCase() != 'true'">
            <a class="nav-link" [routerLink]="'/results/check'">
              <span
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('checkResultLabel')}}">{{
                t('checkResultLabel') }}</span>
            </a>
          </li>
          <li class="nav-item"
            *ngIf="(isAdmin$|async) || (canAccessUserManagement$ | async)">
            <a class="nav-link" [href]="baseAdminUrl$|async">
              <span
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('userManagementLabel')}}">{{
                t('userManagementLabel') }}</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="isSchoolTeacher$ | async">
            <a class="nav-link" [routerLink]="'register-and-test'">
              <span
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('registerAndTestLabel')}}">{{
                t('registerAndTestLabel') }}</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="isLabUser$ | async">
            <a class="nav-link" [routerLink]="'lab-order'">
              <span
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('scanPaperOrderLabel')}}">{{
                t('scanPaperOrderLabel') }}</span>
            </a>
          </li>
          <li class="nav-item geneplanet" *ngIf="cnfg._envConfig.EnablePreventionWellness?.trim()?.toLowerCase() === 'true' && (isAppAccessUser$|async) && (isPreventionWellnessUser$|async)">
            <a class="nav-link" [routerLink]="'prevention-wellness'">
              <span
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('preventionWellness')}}">{{
                t('preventionWellness') }}</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="isDKTenant$ | async">
            <a class="nav-link" href="https://faq.covidresults.dk"
              target="_blank">
              <span
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('faqLinkLabel')}}" target="_blank">{{
                t('faqLinkLabel') }}</span>
            </a>
          </li>
        </ul>
      </div>
      <nav class="my-2 my-md-0 nav-bar-right" aria-label="header-dropdown">
        <div class="dropdown">
          <span
            class="initial-rounded pl-bg-3 pl-text-color-5 pl-border-1 dropdown-toggle"
            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            {{initial}}
          </span>
          <div class="dropdown-menu pl-box-shadow-1"
            aria-labelledby="dropdownMenuButton">
            <div class="arrow-up"></div>
            <div *ngIf="country.length > 1">
              <span class="h-title">Country: </span>
              <a *ngFor="let item of country" (click)="setCountry(item)"
                [ngClass]="{'dropdown-item':true, 'active': activeCountryClass(item)}">{{item.countryName}}-{{item.twoLetterCountryCode}}</a>
              <hr>
            </div>
            <a class="dropdown-item" [href]="cnfg._envConfig.baseProfileUrl"
              target="_blank">
              <em class="fas fa-user"></em>
              {{ t('myAccountLabel') }}
            </a>
            <a class="dropdown-item" [href]="bookingUrl$|async" _target="_black"
              [routerLinkActive]="['nav-active']">
              <span>{{ t('bookingLinkLabel') }}</span>
            </a>
            <a class="dropdown-item" (click)="startSignoutMainWindow()"
              href="javascript:;">
              <em class="fas fa-sign-out-alt"></em>
              {{ t('logoutLabel') }}
            </a>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>

<ng-container *transloco="let t; read: 'header'">
  <nav aria-label="plasma-header"
    class="new-header plasma-header web-header navbar navbar-expand-lg navbar-light bg-light new-header border-bottom box-shadow sticky-top  pl-bg-2 "
    [ngClass]="{'white-labeled-icon-dk': isDKTenant$|async}">
    <button class="navbar-toggler pl-0" type="button" data-toggle="collapse" (click)="onOpenHeaderMenu()"
      data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="material-icons">menu</span>
    </button>
    <a [routerLink]="redirectRoute$ | async" class="navbar-brand ml-2">
      <img [src]="logo$|async" alt="SYNLAB's logo"
        class="img-fluid pl-logo-1" />
    </a>

    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item">
          <!-- toggle close -->
          <button class="navbar-toggler collapsed d-block d-lg-none  pl-0" (click)="onCloseHeaderMenu()"
            type="button" data-toggle="collapse"
            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
            aria-expanded="false" aria-label="Toggle navigation">
            <span class="material-icons">close</span>
          </button>
        </li>

        <li class="nav-item" *ngFor="let nav of nav; let first = first;">
          <a class="nav-link" [routerLink]="nav.route"
            *ngIf="auth.checkUserAccess(nav.access)|async" data-toggle="collapse"
            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
            aria-expanded="false">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{nav.name}}">{{nav.name}}</span>
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link my-results"
            *ngIf="!(isDFPAdmin$ | async) && !(isLabUser$ | async) && !((canAccessCheckResult$ | async) && (!(isCustomerCare$ | async) || ((isCustomerCare$ | async) && !(isDKTenant$ | async)))) || (isMassTester$ | async) || ((isEETenant$|async))"
            [routerLink]="fullCatalogueViewEnabled ? '/results/category' : '/results/list'" data-toggle="collapse"
            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
            aria-expanded="false">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{ fullCatalogueViewEnabled && (isSuperSupport$|async) ? t('checkResultLabel') : t('myResultTabLabel') }}">
              {{ fullCatalogueViewEnabled && (isSuperSupport$|async) ? t('checkResultLabel') : t('myResultTabLabel') }}
           </span>
          </a>
        </li>
        <li class="nav-item foreign-id">
          <a class="nav-link" *ngIf="isDKTenant$ | async"
            [href]="cnfg._envConfig.baseProfileUrl">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('foreignIdLabel')}}">{{ t('foreignIdLabel')
              }}</span>
          </a>
        </li>

        <!-- SA-356: 'My Results' and 'Booking' are removed from the view of the Lab User -->
        <li class="nav-item booking"
          *ngIf="!(isLolliLabAdmin$ | async) && !(isAppAccessUser$ | async) && !(isDFPAdmin$ | async) && (bookingLinkEnabled$|async) && !(canAccessCheckResult$|async) && !(isSchool$|async)
            || ((bookingLinkEnabled$|async) && (isNotWhiteLabled$|async) && (isAppAccessUser$|async))
          ">
          <a class="nav-link" [href]="bookingUrl$|async">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('bookingLinkLabel')}}">{{
              t('bookingLinkLabel') }}</span>
          </a>
        </li>
        <li class="nav-item geneplanet" *ngIf="!(isAppAccessUser$ | async) && cnfg._envConfig.EnablePreventionWellness?.trim()?.toLowerCase() === 'true' && (isAppAccessUser$|async) && (isPreventionWellnessUser$|async)">
          <a class="nav-link" [routerLink]="'prevention-wellness'"
            data-toggle="collapse"
            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('preventionWellness')}}">{{
              t('preventionWellness') }}</span>
          </a>
        </li>
        <li class="nav-item"
          *ngIf="(isAdmin$|async) || (canAccessCheckResult$ |async) && cnfg._envConfig.FullCatalogueViewEnabled?.toLowerCase() != 'true'">
          <a class="nav-link" [routerLink]="fullCatalogueViewEnabled ? '/results/category' : '/results/check'" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('checkResultLabel')}}">{{
              t('checkResultLabel') }}</span>
          </a>
        </li>
        <li class="nav-item"
          *ngIf="(isSuperSupport$ |async) && cnfg._envConfig.FullCatalogueViewEnabled?.toLowerCase() == 'true'">
          <a class="nav-link" [routerLink]="'/results/category'" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('checkResultLabel')}}">{{
              t('checkResultLabel') }}</span>
          </a>
        </li>
        <li class="nav-item"
          *ngIf="(isAdmin$|async) || (canAccessUserManagement$|async)">
          <a class="nav-link" [href]="baseAdminUrl$|async">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('userManagementLabel')}}">{{
              t('userManagementLabel') }}</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="isSchoolTeacher$ | async">
          <a class="nav-link" [routerLink]="'register-and-test'" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('registerAndTestLabel')}}">{{
              t('registerAndTestLabel') }}</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="isLabUser$|async">
          <a class="nav-link" [routerLink]="'lab-order'" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('scanPaperOrderLabel')}}">{{
              t('scanPaperOrderLabel') }}</span>
          </a>
        </li>

        <!-- create accounts -->
        <li class="nav-item" *ngIf="(isLolliAdmin$ | async) || (isLolliLabAdmin$ | async)">
          <a class="nav-link" [routerLink]="['/createaccount']" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center">{{
              t('createAccountLabel') }}
            </span>
          </a>
        </li>

        <li class="nav-item" *ngIf="(isLolliAdmin$| async)">
        <li class="nav-item" *ngIf="hasUserEditorLink$ | async">
          <a class="nav-link" [routerLink]="'/results/user-editor'">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('userEditor')}}">{{
              t('userEditor') }}</span>
          </a>
        </li>

        <!-- Pool Individual Orders link -->
        <li class="nav-item" *ngIf="capio$|async">
          <a class="nav-link" [routerLink]="['/individual', 'orders']" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center">{{
              t('poolIndividualOrders') }}</span>
          </a>
        </li>

        <!-- Generate Individual Orders -->
        <li class="nav-item" *ngIf="cagop$|async">
          <a class="nav-link" [routerLink]="['/generate', 'orders']" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center">{{
              t('sendCompanyEmployeeOrders') }}</span>
          </a>
        </li>

        <!-- query barcode information -->
        <li class="nav-item" *ngIf="hasCreatePoolOrderLink$|async">
          <a class="nav-link" [routerLink]="['/createpoolorder']" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center">{{
              t('createPoolOrderLabel') }}
            </span>
          </a>
        </li>
        <li class="nav-item" *ngIf="hasCreatePoolOrderLink$|async">
          <a class="nav-link" [routerLink]="['/individualtesting']" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center">{{
              t('individualTesting') }}
            </span>
          </a>
        </li>
        <!-- query barcode information -->
        <li class="nav-item" *ngIf="hasQueryBarcodeLink$|async">
          <a class="nav-link" [routerLink]="['/generate', 'querybarcodeinfo']" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center">{{
              t('queryBarcodeInfoLabel') }}
            </span>
          </a>
        </li>

        <li class="nav-item" *ngIf="(isDKTenant$ | async)">
          <a class="nav-link" href="https://faq.covidresults.dk"
            target="_blank">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('faqLinkLabel')}}" target="_blank">{{
              t('faqLinkLabel') }}</span>
          </a>
        </li>
        <!-- show only in mobile view -->
        <li class="nav-item d-block d-lg-none">
          <a href="javascript: void 0;" class="nav-link px-3"
            (click)="startSignoutMainWindow()">
            <span
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('logoutLabel')}}">{{ t('logoutLabel')
              }}</span>
          </a>
        </li>
      </ul>
    </div>
    <nav class="my-2 my-md-0 nav-bar-right" aria-label="lang-picker">
      <app-language-picker></app-language-picker>
      <div class="dropdown">

        <!-- small devices -->
        <a class="d-block d-lg-none dropdown-toggle"
          [href]="baseProfileUrl$|async">
          <img src="/assets/images/new/personal-information.png" alt="">
        </a>

        <!-- desktop view -->
        <span
          class="initial-rounded pl-bg-3 pl-text-color-5 pl-border-1 dropdown-toggle mr-2 d-none d-lg-block d-xl-block"
          id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          {{ initial }}
        </span>

        <div class="dropdown-menu pl-box-shadow-1"
          aria-labelledby="dropdownMenuButton">


          <div class="arrow-up"></div>
          <div *ngIf="country.length > 1">
            <span class="h-title">Country: </span>
            <a *ngFor="let item of country" (click)="setCountry(item)"
              [ngClass]="{'dropdown-item':true, 'active': activeCountryClass(item)}">{{item.countryName}}-{{item.twoLetterCountryCode}}</a>
            <hr>
          </div>
          <a class="dropdown-item" [href]="cnfg._envConfig.baseProfileUrl"
            target="_blank">
            <em class="fas fa-user"></em>
            {{ t('myAccountLabel') }}
          </a>
          <a class="dropdown-item" (click)="startSignoutMainWindow()"
            href="javascript:;">
            <em class="fas fa-sign-out-alt"></em>
            {{ t('logoutLabel') }}
          </a>

        </div>
      </div>
    </nav>
  </nav>
</ng-container>
