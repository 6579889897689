<div class="container py-5">
    <div class="page-title-wrap text-center">
        <div class="page-title p-0 m-0">Search for patient</div>
        <p>You can search on social security number or name to find the patient you want to create order for.</p>
        <div class="text-left">
            <div class="form-group form-inline my-3">
                <div class="form-group has-search">
                    <span class="form-control-feedback">
                        <img src="assets/images/search.png" alt="" class="img-fluid" />
                    </span>
                    <ng-select #elem [placeholder]="phVal" [loading]="loadapi" [closeOnSelect]="true"
                        [isOpen]="isOpen" (focus)="closeOnClick()" (change)="change()"
                        (search)="state.page = 1; load($event);" (clear)="closeOnClick()" (add)="closeOnClick()"
                        [items]="pl" [(ngModel)]="sv">
                    </ng-select>
                </div>
                <a class="ml-1 mt-2" *ngIf="showReg" [routerLink]="['/create-new-patient']">Register Patient</a>
            </div>
        </div>
    </div>
</div>