<div class="info message-container" *transloco="let t; read: 'labOrder'">
  <div class="full">
    <div class="box d-flex">
      <div class="box-icon">
        <span class="material-icons">info</span>
      </div>
      <div class="message">
        <div class="title">
          {{ t('existingOrderTitle') }}
        </div>
        <p class="description">
          {{ t('existingOrderMessage') }}
        </p>
      </div>
      <div class="close-btn" (click)="close()">
        <span class="material-icons">close</span>
      </div>
    </div>
  </div>
</div>
