<div class="container pt-5 pb-5">
  <div class="row">
    <div class="col-12 col-sm-12">
      <div class="card p-0 pl-box-shadow-1">
        <div class="card-body verify-form">
            <h3 class="title-mb py-3 px-4"><em class="fas fa-user icon-color"></em>  Register Patient</h3>

            <form class="mt-4 py-3 px-4">
                <div class="form-group row">
                    <label class="control-label col-sm-4" for="email">First Name:</label>
                    <div class="col-sm-8">
                      <input [(ngModel)]="patientData.given_name" name="firstName" class="form-control" type="text" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="control-label col-sm-4" for="email">Surname:</label>
                    <div class="col-sm-8">
                      <input  [(ngModel)]="patientData.family_name" name="surname" class="form-control" type="text" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="control-label col-sm-4" for="email">Email:</label>
                    <div class="col-sm-8">
                      <input [(ngModel)]="patientData.email" name="email" class="form-control" type="text" />
                    </div>
                </div>
                <div class="form-group row">
                  <label class="control-label col-sm-4" for="country">Country:</label>
                  <div class="col-sm-8">
                    <input [disabled]="true" [(ngModel)]="data.selectedCountry.countryName" name="country" class="form-control" type="text" />
                  </div>
              </div>
                <div class="form-group row">
                    <label class="control-label col-sm-4" for="ssn">Social Security:</label>
                    <div class="col-sm-8">
                      <input [(ngModel)]="patientData.ssn" name="ssn" class="form-control" type="text" />
                    </div>
                </div>
                <div class="text-right">
                  <button (click)="back()" class="btn btn-margin btn-outline-primary">
                    Cancel
                  </button>
                  <button class="btn btn-primary pull-right" (click)="createPatient()" [routerLink]="['./']">
                    Send Invitation
                  </button>
                </div>
              </form>

         
        </div>
      </div>
    </div>
  </div>
</div>